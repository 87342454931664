<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full w-full overflow-y-auto">
			<n-data-table title="庫存列表" :columns="fields" :data="inventories" actions="view,edit,search,refresh" @view="getInventoryObject" @edit="archiveInventoryObject">
				<template v-slot:merchantId="{ row }"> {{ merchantName(row.merchantId).name['tc'] }}</template>
				<template v-slot:locationId="{ row }"> {{ locationName(row.locationId).name['tc'] }}</template>
				<template v-slot:productId="{ row }"> {{ catalogItem(row.productId).name['tc'] }}</template>
				<template v-slot:variantId="{ row }"> {{ catalogItem(row.variantId).name['tc'] }}</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ inventory.id ? '修改' : '新增' }}庫存</p>

			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select
					v-model="inventory.merchantId"
					:options="merchants"
					@input="
						inventory.locationId = ''
						inventory.productId = ''
					"
					:reduce="merchant => merchant.id"
					:get-option-label="merchant => merchant.name['tc']"
					class="w-full h-full text-base"
					placeholder="請選擇商戶"
				></n-select>
			</div>

			<div v-if="inventory.merchantId" class="flex space-x-2">
				<label for="name">位置類別</label>
				<n-select v-model="inventory.locationId" :options="locationByMerchantId(inventory.merchantId)" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
			</div>

			<div v-if="inventory.merchantId" class="flex space-x-2">
				<label for="name">商品</label>
				<n-select v-model="inventory.productId" :options="catalogByMerchantId(inventory.merchantId, 'PRODUCT')" @input="inventory.variantId = ''" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
			</div>

			<div v-if="inventory.productId" class="flex space-x-2">
				<label for="name">變化</label>
				<n-select v-model="inventory.variantId" :options="catalogByMerchantId(inventory.merchantId, 'VARIANT')" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
			</div>

			<div v-if="inventory.productId" class="flex space-x-2">
				<label for="name">變化</label>
				<n-input type="number" v-model.number="inventory.quantity" placeholder="請輸入數量" />
			</div>

			<div class="flex space-x-2">
				<label for="status">庫存狀態</label>
				<n-select v-model="inventory.status" disabled class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
			</div>

			<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

			<div class="flex space-x-2">
				<n-button v-if="inventory.id" @onClick="$router.go(0)" color="danger">返回</n-button>
				<n-button @onClick="inventory.id ? updateInventoryObject(inventory) : createInventoryObject(inventory)">{{ inventory.id ? '修改庫存' : '加入庫存' }}</n-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { merchant } from '@/model/merchant'
import { inventory } from '@/model/inventory'

export default {
	name: 'Home',
	data() {
		return {
			inventory: inventory(),
			merchant: merchant(),
			fields: [
				{
					label: '所屬商戶',
					prop: 'merchantId',
					sortable: true,
				},
				{
					label: '庫存位置',
					prop: 'locationId',
					sortable: true,
				},
				{
					label: '商品',
					prop: 'productId',
					sortable: true,
				},
				{
					label: '變化',
					prop: 'variantId',
					sortable: true,
				},
				{
					label: '數量',
					prop: 'quantity',
					sortable: true,
				},
				{
					label: '庫存狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},

	computed: {
		...mapState(['statusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			'currentMerchant',
			// location
			'locations',
			'locationByMerchantId',
			'locationName',
			// catalog
			'catalog',
			'catalogItem',
			'catalogByMerchantId',
			'catalogMerchant',
			// inventory
			'inventories',
			'currentInventory',
			// adjustmentOrder
			// transferOrder
			// state
			'statusName',
		]),
	},
	methods: {
		async createInventoryObject(data) {
			try {
				await this.$store.dispatch('createInventoryObject', { inventoryObject: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getInventoryObject(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getInventoryObject', { id })
				this.inventory = this.currentInventory
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveInventoryObject(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveInventoryObject', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateInventoryObject(data) {
			try {
				this.error = ''
				await this.$store.dispatch('updateInventoryObject', { id: data.id, inventoryObject: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
