export function inventory() {
	return {
		merchantId: '',
		locationId: '',
		productId: '',
		variantId: '',
		quantity: 0,
		status: 'IN_STOCK',
	}
}
